.metadata {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.metadata > p {
    margin: 0;
    font-weight: 800;
}

.metadata-container {
    display: flex;
    gap: 1rem;
}
.metadata-img {
    width: 3rem;
    height: 3rem;
}
