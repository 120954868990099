.selected {
    color: red;
}

.heading {
    font: normal 16px serif;
    font-size: 22px;
    letter-spacing: 10px;
    padding: 55px 0 40px;
    background: #fff;
    flex-flow: row;
    word-break: break-word;
    min-width: 0;
    text-align: center;
    text-transform: uppercase;
    position: relative;
}

h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.center-button, .heading {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.heading:before {
    margin-right: 40px;
    word-break: break-word;
}
.heading:after, .heading:before {
    content: "";
    display: block;
    background: #000;
    background: rgba(0,0,0,.5);
    height: 1px;
    width: 50px;
}
.heading:after {
    margin-left: 30px;
    word-break: break-word;
}


/*  */

.pics:hover{
 filter: opacity(.8);
}
.gallery{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    /* display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 4fr)); */
    /* grid-template-rows: repeat(2,1fr); */
    /* justify-content: center; */
    /* height: 18%; */
}
.gallery>.pics{
    width: 20%;
    /* width: 89%; */
    /* margin-left: 20px; */
    /* height: 18%; */
    /* padding: 18px; */

}
.pics{
    /* width: 300px;
    height: 300px; */
    -webkit-transition: all 350ms ease ;
transition: all 350ms ease;
cursor: pointer;
/* margin-bottom: 3.5rem; */

}

.single-insp-pics {
    width: 20%;
    height: 200px;
    -webkit-transition: all 350ms ease ;
    transition: all 350ms ease;
    cursor: pointer;
    overflow: hidden;
}

.insp-pics {
    width: 30%;
    height: 400px;
    -webkit-transition: all 350ms ease ;
    transition: all 350ms ease;
    cursor: pointer;
    overflow: hidden;
}
.gallery-post .title {
    font: normal 16px AvenirNextLTPro-Medium,Verdana;
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 2px;
    padding: 20px 0;
    width: calc(100% - 50px);
    float: left;
    margin-bottom: 20px;
}

.gallery-post .arrow {
    width: 12px;
    height: 1px;
    background-color: #000;
    transition: all .35s ease-out;
    float: right;
    position: relative;
    margin: 27px 1px;
    margin-right: 5px;
}
.gallery-post .arrow:after {
    content: "";
    display: inline-block;
    width: 5px;
    height: 5px;
    border-top: 1px solid #000;
    border-right: 1px solid #000;
    position: absolute;
    right: 0;
    top: -50%;
    -webkit-transform: translateY(-40%) rotate(45deg);
    transform: translateY(-40%) rotate(45deg);
}
/* ----my new css media query-------- */
@media (max-width:900px) {
    .gallery{
        /* display: grid; */
        /* grid-template-columns: repeat(3,1fr);
        grid-template-rows: repeat(3,1fr); */
    }
    .single-insp-pics {
        width: 30%;
    }
    .insp-pics {
        width: 30%;
    }
}
@media (max-width:600px) {
    .gallery{
        /* display: grid; */
        /* grid-template-columns: repeat(2,1fr);
       grid-template-rows: repeat(4,1fr); */
    }
    .single-insp-pics {
        width: 80%;
    }
    .insp-pics {
        width: 60%;
    }
}



@media (max-width:1291px){
    .gallery{
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count:2;
    }
    }
    @media (max-width:780px){
        .gallery{
            -webkit-column-count: 1;
            -moz-column-count: 1;
            column-count:1;
            -webkit-column-width: 100%;
            -moz-column-width: 100%;
            column-width: 100%;
        }
}

@media (max-width:1291px){
    .gallery-post .arrow {
     visibility: hidden;
    }
    }
    @media (max-width:780px){
   .gallery-post .arrow {
     visibility: hidden;
    }
    }

    .center-button {
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
    }
    .center-button {
        margin: 50px auto;
        flex-flow: row nowrap;
    }
    .button-animated {
        background: transparent;
        font: normal 16px AvenirNextLTPro-Medium,Verdana;
        border: 2px solid #ffffff;
        min-width: 86px;
        height: 66px;
        justify-content: center;
        padding: 0 28px;
        display: inline-block;
        font-size: 10px;
        color: #fff;
        letter-spacing: .3em;
        text-transform: uppercase;
        cursor: pointer;
        z-index: 1;
        overflow: hidden;
        transition: color .5s cubic-bezier(.785,.135,.15,.86);
        
    }
   
    .button {
        font: normal 16px AvenirNextLTPro-Medium,Verdana;
        padding: 10px 20px;
        background: #000104;
        color: #fff;
        display: inline-block;
        transition: .18s ease;
        border: 2px solid #9196a5;
        cursor: pointer;
        justify-content: center;
        
    }
   
