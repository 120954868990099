nav {
    display: flex;
    gap: 1rem;
    font-family: "brandon-bold" !important;
}
.hidden {
    display: none;
   
}
.show{
   display: block;
   

}
.nav-links{
   display: flex;
   flex-direction: column;
   transition: all 0.3s ease-in-out;

   /* transition-delay: 5s; */
  
}
.links{
   padding: 1rem;
   width: 100%;
   text-align: center;
   background-color: lightgray;
}
.link {
   font-size: 1.5rem;
   font-weight: bold;
   color:black;
}

@media (min-width:992px) {
   .show {
       display: none;
   }
}