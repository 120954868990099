/* section 1 */
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
div {
  display: block;
}
body {
  text-align: left;
  height: 100%;
  width: 100%;
  font-size: 14px;
  line-height: 1.42857143;
  font-family: MontserratRegular, Arial, sans-serif;
  background-color: #fff;
  color: #000;
  background-color: #fff;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.btn_heading {
  min-height: 50px;
  color: #fff;
  font-family: BodoniSvtyTwoITCTTBook;
  background-color: #691831;
  border-radius: 3px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 20px;
  line-height: 50px;
  font-weight: bold;
}
@media (max-width: 1200px) {
  .prodcut-name-details {
    padding-bottom: 5px !important;
  }
}

.prodcut-name-details {
  padding-bottom: 10px;
}
.line-border {
  border-bottom: 3px solid #eee7e9;
  padding: 3px 0;
}
.BodoniSvtyTwoITCTTBook {
  font-family: BodoniSvtyTwoITCTTBook;
}
.div_main {
  margin-left: 1px;
}
.productHeadSamples {
  margin-top: 10px;
  cursor: pointer;
}
.productHeadSamples:hover {
  color: #60152b !important;
  cursor: pointer;
}

.productHeadSamples.img:hover {
  cursor: pointer;
  color: #60152b !important;
}
@media (min-width: 1024px) {
  .prodcut-name {
    font-size: 42px;
    line-height: 42px;
  }
}

.prodcut-name,
.prodcut-color {
  color: #60152b;
  font-size: 32px;
}
.color-icon {
  padding-bottom: 5px !important;
  display: flex;
}

@media (max-width: 1200px) {
  .color-icon {
    padding-bottom: 5px !important;
    display: flex;
  }
}
.productHeadSamples {
  color: #b4a18e !important;
  background-color: #f1edea;
  float: left;
  width: 50%;
}

@media (min-width: 1440px) {
  .productHeadButtons {
    float: left;
    width: 100%;
  }
}
.btn-orange-lg:hover {
  color: #fff;
  background-color: #d06c15;
}
.btn:hover,
.btn:focus,
.btn.focus {
  color: #333333;
  text-decoration: none;
}
.btn-orange-lg {
  width: 100%;
  border-radius: 0;
  color: #f1edea;
  font-size: 18px;
  background-color: #f6890e;
  border: 0px solid;
  font-family: MontserratBold, sans-serif;
  height: 75px;
  letter-spacing: 2px;
  text-transform: uppercase;
  line-height: 1.5;
}
.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.productHeadKitchen img {
  width: 100%;
}
img {
  vertical-align: middle;
}

.fa-angle-down {
  font-size: 20px;
}
.fas {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}
.carousel-indicators .settin {
  width: 800px !important;
}
.carousel-indicators .settin:not(.active) {
  opacity: 0.9;
}
.carousel-indicators {
  /* position: static; */
}

/* section 2 */
.divider {
  padding: 20px 0;
  border-bottom: 2px solid #eee7e9;
  border-top: 2px solid #eee7e9;
  margin: 30px 0;
}
.darkred {
  color: #691831 !important;
}

.shop-list {
  line-height: 1.4;
  margin-bottom: 10px;
  color: #838587;
}

.shop-list li {
  list-style: disc;
}

.shop-list ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.shop-description h4 {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.shop-description p {
  color: #838587;
  margin: 0 0 10px;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.divicon {
  background-color: #f1edea;
  color: #010000;
  min-height: 60px;
  margin-bottom: 10px;
  padding-left: 10px;
  font-family: MontserratRegular;
}
@media (max-width: 770px) {
  .single-product-row{
    display: none;
  }
  .container > img{
    width: 250px;
    height: 450px;
  }
  .container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
}
@media (min-width: 980px) {
  .divider .divicon img {
    float: left;
  }
}
@media (min-width: 1440px) {
  .divider .divicon img {
    float: left;
    padding-left: 10px;
  }
}
.divider .divicon img {
  height: 60px;
}
@media (min-width: 980px) {
  .divider .divicon img {
    float: left;
  }
}
@media (min-width: 1440px) {
  .divider .divicon img {
    float: left;
    padding-left: 10px;
  }
}
.divider .divicon img {
  height: 60px;
}

img {
  vertical-align: middle;
}
img {
  border: 0;
}

/* section 3 */

.shopping-cart-wrap .cart-table .table thead tr th {
  background-color: #ffffff;
  color: #691831 !important;
  font-size: 16px;
  font-weight: 600;
  padding: 18px 20px;
  line-height: 1;
  border-top: medium none;
  text-align: center;
  text-transform: capitalize;
  vertical-align: middle;
  white-space: nowrap;
}
.select-Darkred {
  background-color: #691831;
  color: #fff;
}
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
select {
  text-transform: none;
}

.shopping-cart-wrap .dark h1 {
  color: #691831 !important;
  background-color: #f1edea;
  font-size: 18px;
  padding: 12px !important;
  text-align: left;
}

@media (max-width: 1400px) {
}

@media (max-width: 1366px) {
  .cont .divider {
    margin-top: 90px;
  }
  .divicon {
    width: 100% !important;
    margin-bottom: 5px;
  }
}
@media (max-width: 1024px) {
  .divicon {
    background-color: #f1edea;
    color: #010000;
    min-height: 60px;
    margin-bottom: 10px;
    padding-left: 10px;
    font-family: MontserratRegular;
  }
}

@media (max-width: 500px) {
}
