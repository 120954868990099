/* HeroImage */
.bg {
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)), url(../assets/images/AboutUs.jpg);
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, 0);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; /* This will center the image */
    height: 50vh; /* Use 100vh for full viewport height */
    display: flex; /* Added for vertical centering */
    align-items: center; /* Added for vertical centering */
    background-attachment: fixed;
}

@media (max-width: 767px) { /* Media query for mobile devices */
    .bg {
        height: 25vh; /* Adjust the height for mobile devices */
    }
}

.container {
    max-width: 1400px;
    width: 96%;
    margin: 0 auto;
}
/* HeroImage */

/* about */
.our-brand {
    padding: 0px 0 0px;
}
.fancy-heading {
    font: normal 16px AvenirNextLTPro-Medium,Verdana;
    font-size: 22px;
    letter-spacing: 10px;
    padding: 55px 0 40px;
    background: #fff;
    flex-flow: row;
    word-break: break-word;
    min-width: 0;
    text-align: center;
    text-transform: uppercase;
    position: relative;
}
.center-button, .fancy-heading {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.our-brand_content {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row nowrap;
    justify-content: space-around;
    -webkit-box-align: center;
    align-items: center;
    /* padding-top: 50px; */
}
h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}
 .huge-heading {
    position: relative;
    font-size: 1.0em;
    letter-spacing: 3.2px;
    font: MinionPro-Medium;
    font-weight: 600;
}
.our-brand_caption {
    font-size: 55px;
    max-width: 400px;
}
.our-brand_paragraph {
    max-width: 450px;
    font-size: 16px;
    color: #aaa;
    line-height: 1.9;
    justify-content: center;
    margin-bottom: calc(5vw + 50px); /* Dynamic margin to avoid overlap with growth_image:before */
}

.fancy-heading:before {
    margin-right: 40px;
    word-break: break-word;
}
.fancy-heading:after, .fancy-heading:before {
    content: "";
    display: block;
    background: #000;
    background: rgba(0,0,0,.5);
    height: 1px;
    width: 50px;
}

.fancy-heading:after {
    margin-left: 30px;
    word-break: break-word;
}

*, :after, :before {
    box-sizing: inherit;
}


/*  */


/* about */

.paralexing_container {
    width: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row nowrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    align-items: flex-start;
}

.paralexing_content {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row nowrap;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    padding-right: 15%;
}
.loaded-bg {
    background: #000104;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
}

.growth_heading {
    font: normal 16px AvenirNextLTPro-Medium,Verdana;
    font-size: 36px;
    margin-bottom: 40px;
    color: #fff;
}
.growth_p {
    color: #757575;
    font-size: 18px;
    line-height: 2;
    width: 100%;
}

@media (min-width: 1224px)
{
    .growth_content {
        padding: 90px;
    }
    
    .paralexing_image:last-child {
        margin-left: -15%;
    }
    .paralexing_image {
        width: 100%;
        margin-top: -120px;
    }
    .growth_image:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: 77.00205%;
    }
}
@media (max-width: 1224px)
{
    .growth_content {
        padding: 100px;
    }
  
}

.growth_image {
    background: url(../assets/images/AboutUs-2.jpg);
    background-position: 50% -10%;
    background-repeat: no-repeat;
    position: relative;
    background-size: cover;
}


/* carts */
.carts {
    padding: 200px 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}
.carts_item {
    padding: 60px 0;
    margin: 20px 0;
    align-self: stretch;
    width: 320px;
    padding: 0 20px;
    border-right: 1px solid #f1f1f1;
    text-align: center;
    box-sizing: border-box;
}
.carts_heading {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row nowrap;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    font: normal 16px AvenirNextLTPro-Medium,Verdana;
    font-size: 75px;
    margin-bottom: 20px;
}
.carts_h2 {
    font: normal 16px AvenirNextLTPro-Medium,Verdana;
    font-size: 17px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 20px;
}
.carts_p {
    font: normal 16px AvenirNextLTPro-Medium,Verdana;
    width: 70%;
    color: #aaa;
    margin: 0 auto;
    font-size: 18px;
    line-height: 1.7;
    font-style: italic;
}

/* Q12 */

.quality-check {
    background: #fafafa;
    position: relative;
    padding-bottom: 196px;
    overflow: hidden;
}


.quality-check_heading {
    padding: 100px 60px 60px 0;
}
.quality-check_wording {
    padding: 0 0 80px;
    width: 100%;
    max-width: 800px;
}
.quality-heading--dark {
    color: #000104;
    background: none;
}
.quality-heading {
    font: normal 16px AvenirNextLTPro-Medium,Verdana;
    font-size: 22px;
    letter-spacing: 10px;
    padding: 55px 0 40px;
    flex-flow: row;
    word-break: break-word;
    min-width: 0;
    text-align: center;
    text-transform: uppercase;
    position: relative;
}
.center-button, .quality-heading {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}
.quality-heading:before {
    margin-right: 40px;
    word-break: break-word;
}

.quality-heading:after {
    margin-left: 30px;
    word-break: break-word;
}
.quality-heading:after, .quality-heading:before {
    content: "";
    display: block;
    background: #000;
    background: rgba(0,0,0,.5);
    height: 1px;
    width: 50px;
}
.quality-check_wording {
    padding: 0 0 80px;
    width: 100%;
    max-width: 800px;
}
.quality-check_h4 {
    font: normal 16px AvenirNextLTPro-Medium,Verdana;
    font-size: 60px;
    margin-bottom: 65px;
    max-width: 350px;
}
.quality-check_h4:after {
    content: "";
    display: block;
    width: 70px;
    height: 8px;
    margin-top: 40px;
    background: #000104;
}
.quality-check_columns {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row nowrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
}
.quality-check_columns span {
    width: 100%;
    max-width: 380px;
    color: #aaa;
    font-size: 16px;
    line-height: 1.7;
    padding-bottom: 35px;
    margin-right: 35px;
}

@media (max-width: 1600px)
{.quality-check_image {
    width: 400px;
    height: 568px;
}}
@media (max-width: 1000px)
{.quality-check_image {
  visibility: hidden;
}}

.quality-check_image {
    position: absolute;
    top: 20%;
    right: 0;
    width: 556px;
    height: 724px;
    background: url(https://www.fabuwood.com/assets/images/q12_embossed.png) 50% 50% no-repeat;
    background-size: cover;
}

.quality-check_bgText {
    font: normal 16px AvenirNextLTPro-Medium,Verdana;
    font-size: 760px;
    line-height: 960px;
    position: absolute;
    top: 0;
    left: -220px;
    letter-spacing: -20px;
    z-index: 100;
    color: #000;
    color: rgba(0,0,0,.03);
}



.quality-check_wording {
    padding: 0 0 80px;
    width: 100%;
    max-width: 800px;
}

