/* HeroImage */
.bg-main{
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)), url(../assets/images/ContactUs.jpg);
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, 0);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; /* This will center the image */
    height: 50vh; /* Use 100vh for full viewport height */
    display: flex; /* Added for vertical centering */
    align-items: center; /* Added for vertical centering */
    background-attachment: fixed;

}

@media (max-width: 767px) { /* Media query for mobile devices */
    .bg-main {
        height: 25vh; /* Adjust the height for mobile devices */
    }
}

.container {
    max-width: 1400px;
    width: 96%;
    margin: 0 auto;
}

/*  */



.contact_other {
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    max-width: 930px;
    margin: -200px auto 300px;
    padding: 0 55px 80px;
    background: #fff;
    -webkit-box-pack: center;
    justify-content: center;
    box-shadow: 0 0 70px hsl(0deg 0% 63% / 30%);
}
.contact_other .form-page {
    padding-bottom: 30px;
}

.form-page {
    font: normal 16px AvenirNextPro,Verdana;
    line-height: 2;
    font-size: 18px;
    padding-bottom: 17px;
}
.contact_other .form-section {
    margin: 0;
}
.form-section {
    padding-bottom: 40px;
}
.form-page-heading, .form-page, .form-section {
    max-width: 600px;
    margin: 0 auto;
}
.form-section_fields-container {
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
}

.form-field {
    width: 48%;
    margin-bottom: 25px;
    position: relative;
}
.form-textarea {
    width: 100%;
    margin-bottom: 25px;
    position: relative;
}
.label {
    font: normal 16px AvenirNextLTPro-Medium,Verdana;
    width: 100%;
    text-transform: uppercase;
    margin-bottom: 10px;
    display: inline-block;
    color: #000104;
    letter-spacing: 1px;
    font-size: 14px;
}

.form-field-input {
    font: normal 16px AvenirNextLTPro-Medium,Verdana;
    width: 100%;
    padding: 13px 14px;
    border: 1px solid #aaa;
}

.contact_other .hours {
    font: normal 16px AvenirNextPro,Verdana;
    padding: 30px 0;
    font-size: 18px;
    line-height: 1.6;
}
.btnn {
    background-color: #004964 !important;
    color: #efefef;
    width: 100%;
    height: 50px;
    font-size: 20px;
    font-family: "brandon regular";
    border: 1px solid #004964;
    outline: none;
}
