:root {
    --color-green: #84ff82;
    --gray: #2e2e2e;
}

.home-container {
    font-family: "Gabriela";
    overflow: hidden;
}


.carousel-img {
    height: 40vw;
    object-fit: cover;
}

.carousel-indicators [data-bs-target] {
    background-color: black !important;
    height: 15px;
    width: 15px;
    border-radius: 50%;
}

.carousel-indicators .active {
    background-color: var(--color-green) !important;
    height: 15px;
    width: 15px;
}

.tab-left {
    background-color: #f6890e;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 93px;
}

.tab-right {
    background-color: var(--gray);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 93px;
}

.tab-left>h5 {
    font-size: 29px;
    color: black;
    margin-bottom: 0;
}

.tab-left>p {
    margin-bottom: 0;
    font-size: 17px;
    padding: 5px 0;
    color: white;
}

.tab-right>p {
    margin-bottom: 0;
    font-size: 17px;
    padding: 5px 0;
    color: white;
}

.tab-right>h5 {
    font-size: 29px !important;
    color: white !important;
    margin-bottom: 0;
}

.tab-left:hover,
.tab-right:hover {
    cursor: pointer;
}

.section-2-container {
    height: 953px;
    background-color: var(--gray);
    color: white !important;
}

.section-column-right>img {
    width: 100%;
    height: 796px;
    margin-top: 20px;
    object-fit: cover;
}

.section-column-left {
    margin-top: 20px;
}

.section-column-left>h1 {
    font-size: 85px;
    width: 557px;
    display: block;
    margin: auto;
    margin-bottom: 0.5rem;
}

.section-column-left>.description {
    font-size: 29px;
    width: 100%;
    display: block;
    margin: auto;
    line-height: 37.15px !important;
    text-align: justify;
    text-justify: inter-word;
    padding: 15px;
}

.page-link {
    color: white !important;
    border: 0;
    background-color: transparent !important;
    font-size: 29px;
}

.page-link:hover {
    cursor: pointer;
}

nav {
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 30px !important;
}

.section-3-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 30px;
}

.section-3-images>.image-links {
    width: 165px;
    height: 360px;
    margin-left: 20px;
    margin-bottom: 20px;
}

.image-links>img {
    width: 100%;
    height: 100%;
}

.section-3-container>h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 88px;
    text-align: center !important;
    margin: 30px 0;
}

/* -------------------  Mobile View ----------------------  */
@media (max-width: 600px) {

    .section-3-images {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 0.5rem;
    }

    .carousel-img {
        height: 40vw;
        object-fit: cover;
    }

    .tab-left {
        width: 100%;
        height: 60px;
    }

    .tab-right {
        height: 60px;
        width: 100%;
    }

    .tab-left>h5 {
        font-size: 25px;
        color: black;
    }

    .tab-right>h5 {
        font-size: 25px !important;
    }

    .tab-left:hover,
    .tab-right:hover {
        cursor: pointer;
    }

    .section-2-container {
        height: auto;
        padding-top: 25px;
    }

    .section-2-div {
        display: flex;
        flex-direction: column-reverse;
    }

    .section-column-right>img {
        height: 400px;
        width: 400px;
        display: block;
        margin: auto;
        object-fit: cover;
    }

    .section-column-left>h1 {
        font-size: 50px;
        padding: 10px 20px;
        width: 100%;
    }

    .section-column-left>p {
        font-size: 20px;
        width: 100%;
        padding: 10px 20px;
    }

    .page-link {
        color: white !important;
        border: 0;
        background-color: transparent !important;
        font-size: 12px;
        padding: 0 3px;
    }

    .section-3-container>h3 {
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 88px;
        text-align: center !important;
        margin: 30px 0;
    }

    .section-3-images>.image-links {
        width: 90%;
        height: 90%;
        margin-left: -1%;
    }

    .image-links>img {
        width: 100%;
        height: 100%;
    }

    .carousel-caption.custom-slider h1 {
        font-size: 1.8rem !important;
    }

    .carousel-caption.custom-slider p {
        font-size: 0.8rem;
    }
}

.animation-text {
    font-size: 5rem;
    position: absolute;
    left: 5%;
    opacity: 0;
    color: #f0eba3;
}

@keyframes bottomFadeOut {
    0% {
        position: absolute;
        bottom: -8rem;
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }

    75% {
        opacity: 0.7;
    }

    100% {
        opacity: 1;
    }
}


.custom-slider {
    left: 5%;
    right: 0%;
    margin-left: 14rem;
    /* margin-bottom: 300px; */
    text-align: left;
    animation-name: bottomFadeOut;
    animation-delay: 0s;
    animation-duration: 1.5s;
}

.carousel-caption.custom-slider {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    top: 50%;
    transform: translateY(-50%);
}

hr {
    background-color: var(--btn-bg);
    width: 250px;
    height: 2px;
    text-align: left;
    margin-left: 0;
}

.custom-slider>h3 {
    font-size: 30px;
    font-weight: 900 !important;
    color: #333;
    font-family: Arial, Helvetica, sans-serif !important;
}

.custom-slider>h1 {
    font-size: 45px;
    font-weight: 800 !important;
    color: black;
    font-family: Arial, Helvetica, sans-serif !important;
}

.custom-slider>p {
    /* font-size: 20px !important; */
    font-family: Arial, Helvetica, sans-serif !important;
    font-weight: 300;
    line-height: 30px;
    word-spacing: 3px;
    width: 50%;
    color: black;
    margin-bottom: 0.3rem !important;
}

.btn-blue {
    color: white;
    border: 0;
    outline: none;
    border-radius: 5px;
    background-color: black;
    padding: 10px 35px;
    font-size: 1rem;
    font-family: Arial, Helvetica, sans-serif !important;
}

.carousel-caption.custom-slider h1 {
    font-size: 2.5rem;
}

.carousel-caption.custom-slider p {
    font-size: 1rem;
}

/*---------- my new css--------- */
/* .section-3-images {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr;
    grid-gap: 0.5rem;
} */

@media (max-width:1170px) {
    .section-3-images {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }
}

/* made changes in the slider for mobile-view */

/* */
@media (max-width: 820px) {

    .carousel-control-prev,
    .carousel-control-next {
        display: none;
    }

    .custom-slider {
        left: 0%;
        right: 0%;
        top: 10%;
        margin-bottom: 2rem !important;
        margin-left: 2rem;
    }

    .custom-slider>h3 {
        font-size: 20px;
    }

    .custom-slider>h1 {
        font-size: 2rem;
        text-align: center;
    }

    .custom-slider>p {
        width: 100%;
        /* font-size: 18px !important; */
        /* font-weight: bold; */
        /* margin-left: 2rem; */
        text-align: center;
    }

    .btn-blue {
        /* margin-top: 3rem; */
        /* font-size: 15px; */
        font-size: 1rem;
        font-family: Arial, Helvetica, sans-serif !important;
        margin-left: 9rem;
    }

    .carousel-img {
        height: 65vw;
        object-fit: cover;
    }

    /* .image-links>img {
        width: 330px !important;
        margin-left: 2rem;
        margin-bottom: 2rem;
        height: 550px !important;
    } */

    .tab-left {
        width: 100%;
        height: 60px;
        padding: 3rem 0;
    }

    .tab-right {
        height: 60px;
        width: 100%;
        padding: 3rem 0;
    }

    .tab-left>h5 {
        font-size: 25px;
        color: black;
    }

    .tab-right>h5 {
        font-size: 25px !important;
    }

    .tab-left:hover,
    .tab-right:hover {
        cursor: pointer;
    }

    .section-2-container {
        height: auto;
        padding-top: 25px;
    }

    .section-2-div {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
    }

    .section-column-right>img {
        height: 400px;
        width: 400px;
        display: block;
        margin: auto;
        object-fit: cover;
    }

    .section-column-left>h1 {
        font-size: 50px;
        padding: 10px 20px;
        width: 100% !important;
    }

    .section-column-left>p {
        font-size: 20px;
        width: 100% !important;
        padding: 10px 20px;
    }

    .page-link {
        color: white !important;
        border: 0;
        background-color: transparent !important;
        font-size: 13px;
        padding: 0 5px;
    }

    /* .section-3-images>img {
        width: 200px;
        height: 300px;
    } */

    .section-3-container>h3 {
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 88px;
        text-align: center !important;
        margin: 30px 0;
    }
}
