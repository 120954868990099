.selected {
    color: red;
}

.heading {
    font: normal 16px serif;
    font-size: 22px;
    letter-spacing: 10px;
    padding: 55px 0 40px;
    background: #fff;
    flex-flow: row;
    word-break: break-word;
    min-width: 0;
    text-align: center;
    text-transform: uppercase;
    position: relative;
}

h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.center-button, .heading {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.heading:before {
    margin-right: 40px;
    word-break: break-word;
}
.heading:after, .heading:before {
    content: "";
    display: block;
    background: #000;
    background: rgba(0,0,0,.5);
    height: 1px;
    width: 50px;
}
.heading:after {
    margin-left: 30px;
    word-break: break-word;
}
.door-card {
    -webkit-box-orient: vertical;
    flex-flow: column;
    max-width: 312px;
    background: #f1f1f1;
    padding: 70px;
    margin: 3px;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
}
.door-card_title {
    font: normal 16px sans-serif;
    text-transform: uppercase;
    padding-top: 40px;
    text-align: center;
    letter-spacing: 1px;
    font-size: 15px;
    color: #000104;
    white-space: nowrap;
}

.series-nav {
    text-align: center;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}
.series-nav_link {
    display: inline-block;
    padding: 10px 18px;
    font: normal 16px AvenirNextLTPro-Cn,sans-serif;
    text-transform: uppercase;
    color: #666;
    font-size: 15px;
    letter-spacing: 1px;
}
.series-nav button:hover{
    
   
    color: #1ABC9C!important;
 
    
}
button {
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    border: none;
}

@media (max-width: 1400px)
{
.door-card  {
    -webkit-box-orient: vertical;
    flex-flow: column;
    max-width: 312px;
    background: #f1f1f1;
    padding: 70px;
    margin: 3px;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
}
.door-card_title {
    font: normal 16px sans-serif;
    text-transform: uppercase;
    padding-top: 20px;
    text-align: center;
    letter-spacing: 1px;
    font-size: 12px;
    color: #000104;
    white-space: nowrap;
    margin-right: 20px!important;
}}
@media (max-width: 1366px)
{
    .door-card  {
        -webkit-box-orient: vertical;
        flex-flow: column;
        max-width: 312px;
        background: #f1f1f1;
        padding: 40px;
        margin: 3px;
        -webkit-box-align: center;
        align-items: center;
        cursor: pointer;
        margin-left: 5%;
margin-bottom: 20px;
        display: block;
    }}
@media (max-width: 1024px)
{
    .door-card  {
        -webkit-box-orient: vertical;
        flex-flow: column;
        max-width: 312px;
        background: #f1f1f1;
        padding: 30px;
        -webkit-box-align: center;
        align-items: center;
        cursor: pointer;
        margin-left: 5%;

        display: block;
    }
    .door-card_title {
        font: normal 16px sans-serif;
        text-transform: uppercase;
        padding-top: 20px;
        text-align: center;
        letter-spacing: 1px;
        font-size: 10px;
        color: #000104;
        white-space: nowrap;
    }
   
}


@media (max-width: 500px)
{
    .door-card_title {
        font: normal 16px sans-serif;
        text-transform: uppercase;
        padding-top: 20px;
        text-align: center;
        letter-spacing: 1px;
        font-size: 14px;
        color: #000104;
        white-space: nowrap;
    }
   
}