@import url("https://fonts.googleapis.com/css2?family=Cookie&family=Dosis:wght@500&family=Gabriela&family=Montserrat:ital,wght@0,400;0,500;0,700;1,800&family=Open+Sans:ital,wght@0,700;1,400&family=Prata&family=Raleway:wght@300&family=Roboto:wght@300&display=swap");
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Gabriela" "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@import "~react-image-gallery/styles/css/image-gallery.css";